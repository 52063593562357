import React from 'react';
import PageSection from '../Cards/PageSection';
import { Grid } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import PartnersCard from '../Cards/PartnersCard';
import CustomersRest from '../Cards/CustomersRest';

const References = () => {
  const data = useStaticQuery(query);
  let isActualPagePostReversed = true;

  return (
    <Grid container>
      {data.allContentfulScanshipPageSection.edges.map(
        (
          {
            node: {
              image: { fluid },
              title,
              description: { description },
            },
          },
          i
        ) => {
          isActualPagePostReversed = !isActualPagePostReversed;
          return (
            <PageSection
              key={title}
              imgFluid={fluid}
              title={title}
              description={description}
              reversed={isActualPagePostReversed}
              paddingTop={i !== 0}
            />
          );
        }
      )}
      <PartnersCard />
      <CustomersRest />
    </Grid>
  );
};

export default References;

const query = graphql`
  query References {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/about-us/references" } }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
  }
`;
