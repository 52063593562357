import React from 'react';
import Layout from '../../components/layout';
import Header from '../../components/Header';
import SubPages from '../../components/Subpages';
import PageContainer from '../../components/Containers/PageContainer';
import Index from '../../components/AboutUs/Index';
import References from '../../components/AboutUs/References';
import Management from '../../components/AboutUs/Management';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/core';
import SIZES from '../../constants/sizes';
import COLORS from '../../constants/colors';
import NAV_ITEMS from '../../constants/nav-items';
import SEO from '../../components/seo';

const subnavItems = NAV_ITEMS[4].subpages;

const useStyles = makeStyles(theme => ({
  title: {
    color: COLORS.DARK_BLUE,
    marginLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT,
    [theme.breakpoints.down('md')]: {
      marginLeft: SIZES.PADDING.LEFT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: SIZES.PADDING.LEFT_PADDING.XS,
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const SUBPAGES_CONTENTS = [Index, References, Management];
  return (
    <Layout>
      <SEO
        title="Scanship | About us"
        description="Scanship is part of Vow which provides solutions that purify wastewater and convert waste into valuable resources and generate clean energy for customers in cruise, aquaculture and a wide range of land-based industries and for utilities."
        keywords={['Scanship']}
      />
      <PageContainer>
        <Header
          sub
          textTransform="lowerCase"
          className={classes.title}
        >
          <FormattedMessage id="aboutUs.title" />
        </Header>
        <SubPages
          navItems={subnavItems}
          contents={SUBPAGES_CONTENTS}
        />
      </PageContainer>
    </Layout>
  );
};

export default AboutUs;
