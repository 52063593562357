import React from 'react';

const Seventh = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 76.02 76.02"
      fill="currentColor"
      {...props}
    >
      <g>
        <path d="M7.77,18.18l8,4.64a1.91,1.91,0,0,1-1.91,3.31l-8-4.64a1.91,1.91,0,1,1,1.91-3.31Zm14-12.46,4.64,8a1.91,1.91,0,0,1-3.31,1.9l-4.64-8a1.91,1.91,0,1,1,3.31-1.91ZM40.06,1.91v9.28a1.91,1.91,0,0,1-3.82,0V1.91a1.91,1.91,0,0,1,3.82,0ZM57.84,7.77l-4.64,8a1.91,1.91,0,1,1-3.31-1.91l4.64-8a1.91,1.91,0,1,1,3.31,1.91Zm12.46,14-8,4.64a1.91,1.91,0,0,1-1.91-3.31l8-4.64a1.91,1.91,0,0,1,1.91,3.31Zm3.81,18.32H64.83a1.91,1.91,0,1,1,0-3.82h9.28a1.91,1.91,0,1,1,0,3.82ZM68.24,57.84l-8-4.64a1.91,1.91,0,0,1,1.91-3.31l8,4.64a1.91,1.91,0,1,1-1.91,3.31Zm-14,12.46-4.64-8A1.91,1.91,0,0,1,53,60.35l4.64,8a1.91,1.91,0,1,1-3.31,1.91ZM36,74.11V64.83a1.91,1.91,0,0,1,3.82,0v9.28a1.91,1.91,0,1,1-3.82,0ZM18.18,68.24l4.64-8a1.91,1.91,0,0,1,3.31,1.91l-4.64,8a1.91,1.91,0,1,1-3.31-1.91Zm-12.46-14,8-4.64A1.91,1.91,0,0,1,15.66,53l-8,4.64a1.91,1.91,0,0,1-1.91-3.31ZM1.91,36h9.28a1.91,1.91,0,1,1,0,3.82H1.91a1.91,1.91,0,1,1,0-3.82ZM38,16.1A21.91,21.91,0,1,0,59.92,38,21.91,21.91,0,0,0,38,16.1Zm-.08,7.44a1.3,1.3,0,0,1,1.29,1.3v7.38a1.3,1.3,0,0,1-2.59,0V24.84A1.3,1.3,0,0,1,37.93,23.54Zm-3.37,3.79v3.82A7.68,7.68,0,1,0,45.69,38h0a7.67,7.67,0,0,0-4.19-6.83V27.35A11.22,11.22,0,0,1,38,49.24h0a11.23,11.23,0,0,1-3.45-21.91Z" />
      </g>
    </svg>
  );
};

export default Seventh;
