import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PersonCard from '../Cards/PersonCard';
import SIZES from '../../constants/sizes';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(theme => ({
  CorporateExecutives: {
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,

    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
    },
    '& >*': {
      marginBottom: 24,
    },
  },
}));

const CorporateExecutives = () => {
  const classes = useStyles();
  const data = useStaticQuery(query);

  return (
    <Grid container className={classes.CorporateExecutives}>
      {data.allContentfulScanshipOrderedList.edges[0].node.items.map(
        ({
          image: { fluid },
          name,
          role,
          phone,
          email,
          description: { description },
        }) => (
          <PersonCard
            imgFluid={fluid}
            key={name}
            name={name}
            role={role}
            phone={phone}
            email={email}
            description={description}
          />
        )
      )}
    </Grid>
  );
};

export default CorporateExecutives;
const query = graphql`
  query CorporateExecutives {
    allContentfulScanshipOrderedList(
      filter: { listId: { eq: "/about-us/management" } }
    ) {
      edges {
        node {
          items {
            ... on ContentfulScanshipCorporateExecutive {
              image {
                fluid {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
              name
              role
              phone
              email
              description {
                description
              }
            }
          }
        }
      }
    }
  }
`;
