import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { string, oneOf, bool } from 'prop-types';
import Seventh from '../Icons/sustainable-dev/Seventh';
import Ninth from '../Icons/sustainable-dev/Ninth';
import Thirteenth from '../Icons/sustainable-dev/Thirteenth';
import Fourteenth from '../Icons/sustainable-dev/Fourteenth';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
//TODO:fix css to make it more responsive.

const getIcon = goalNumber => {
  switch (goalNumber) {
    case 7:
      return <Seventh />;
    case 9:
      return <Ninth />;
    case 13:
      return <Thirteenth />;
    case 14:
      return <Fourteenth />;
    default:
      return <></>;
  }
};
const getColor = goalNumber => {
  switch (goalNumber) {
    case 7:
      return '#FBC412';
    case 9:
      return '#F26A2E';
    case 13:
      return '#407F46';
    case 14:
      return '#1F97D4';
    default:
      return <></>;
  }
};

const useStyles = makeStyles(theme => ({
  SustainableDevCard: {
    //maxWidth: 400,
    userSelect: 'none',
    height: 200,
    overflow: 'hidden',
    color: COLORS.WHITE,
    position: 'relative',
    perspective: 1000,
    [theme.breakpoints.down('xs')]: {
      height: 250,
    },

    '&>div': {
      height: '100%',
      transition: 'transform 1s',
      transformStyle: 'preserve-3d',
      '&>div': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: 24,
        backfaceVisibility: 'hidden',
        backgroundColor: ({ goalNumber }) =>
          getColor(goalNumber),
        [theme.breakpoints.down('xs')]: {
          padding: 15,
        },
      },
      '&>div:nth-child(2)': {
        transform: 'rotateX(-180deg)',
      },
    },

    '&:hover': {
      '&>div': {
        transform: 'rotateX(-180deg)',
      },
    },
    '&.flipped': {
      '&>div': {
        transform: 'rotateX(-180deg)',
      },
    },
  },
  iconContainer: {
    width: 100,
    height: 100,
    '& svg': {
      width: '70%',
      height: '100%',
      display: 'block',
      margin: 'auto',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&>p:nth-child(1)': {
      fontSize: FONTS.SIZE.L,
      fontWeight: FONTS.WEIGHT.BOLD,
      width: 'fit-content',
      display: 'inline-block',
      marginRight: 5,
    },
    '&>p:nth-child(2)': {
      fontSize: FONTS.SIZE.XXS,
      fontWeight: FONTS.WEIGHT.BOLD,
      textTransform: 'uppercase',
      display: 'inline-block',
      whiteSpace: 'pre-line',
    },
  },
  description: {
    fontWeight: FONTS.WEIGHT.MEDIUM,
    fontSize: FONTS.SIZE.XS,
    whiteSpace: 'pre-line',
  },
}));

const SustainableDevCard = ({
  goalNumber,
  title,
  description,
  flipped,
  ...restProps
}) => {
  const classes = useStyles({ goalNumber });

  return goalNumber ? (
    <Grid
      item
      {...restProps}
      className={`${classes.SustainableDevCard} ${
        flipped ? 'flipped' : ''
      }`}
    >
      <Grid container>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.iconContainer}
          >
            {getIcon(goalNumber)}
          </Grid>
          <Grid item xs={12} className={classes.header}>
            <p>{goalNumber}</p>
            <p>{title}</p>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs={12}
            className={classes.description}
          >
            {description}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

SustainableDevCard.propTypes = {
  goalNumber: oneOf([7, 9, 13, 14]).isRequired,
  title: string.isRequired,
  description: string.isRequired,
  flipped: bool,
};

SustainableDevCard.defaultProps = {
  flipped: false,
};

export default SustainableDevCard;
