import React from 'react';

const Thirteenth = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79.98 67.05"
      fill="currentColor"
      {...props}
    >
      <g>
        <path d="M49,31.56c-10.1.24-20,5.57-29.84,12.86C14.8,37,9.09,31.7,0,31.67,8.69,44.6,6.94,56.12,0,66.93c9.09,0,14.8-5.31,19.12-12.75C38.78,68.76,58.74,75.52,80,49.3,69.36,36.19,59.06,31.33,49,31.56Zm9.78,10.2a4.85,4.85,0,0,1,4.85,4.85h0a4.85,4.85,0,1,1-4.85-4.85ZM79.23,14.69l.35,4C72,23.86,66.44,21.8,61.4,17.63c-6.22,4.59-13.07,6-21.41-.3-6.15,3.77-12.45,7.06-21.86.15C13.22,21.31,8.29,25.08.4,18.92L.2,14.74c7.25,4.25,13,1.83,18.38-2.49,7.18,4.86,14.29,6.29,21.21.3,8,7.36,14.77,4.21,21.36-.2,4.83,3.09,9.28,7.06,18.08,2.34Zm0-12.25.35,4C72,11.61,66.44,9.55,61.4,5.38c-6.22,4.59-13.07,6-21.41-.3-6.15,3.77-12.45,7.06-21.86.15C13.22,9.06,8.29,12.83.4,6.67L.2,2.49C7.45,6.74,13.16,4.32,18.58,0c7.18,4.86,14.29,6.29,21.21.3,8,7.36,14.77,4.21,21.36-.2C66,3.19,70.43,7.16,79.23,2.44Z" />
      </g>
    </svg>
  );
};

export default Thirteenth;
