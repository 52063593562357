import React from 'react';
import { string, shape } from 'prop-types';
import {
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';
import { useIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import { formatPhoneNumber } from '../../utils/functions';

const amountLettersToShowAccordion = 150;

const useStyle = makeStyles({
  PersonCard: {
    maxWidth: 970,
    color: COLORS.DARK_BLUE,
    '& *': {
      wordBreak: 'break-word',
    },
    '& a': {
      color: COLORS.BLUE,
      textDecoration: 'none',
      fontWeight: FONTS.WEIGHT.MEDIUM,
      '&:hover': {
        color: 'inherit',
      },
    },
  },
  name: {
    fontSize: FONTS.SIZE.L,
    fontWeight: FONTS.WEIGHT.BOLD,
  },
  accordion: {
    backgroundColor: 'transparent',
    flexDirection: 'column-reverse',
    display: 'flex',
    '& .MuiCollapse-container': {
      minHeight: '78px !important',
      marginBottom: 0,
      '&.MuiCollapse-hidden': {
        height: '78px !important',
        visibility: 'visible',
      },
    },
  },
  rootSummary: {
    padding: 0,
    fontWeight: FONTS.WEIGHT.BOLD,
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& svg': { fill: COLORS.DARK_BLUE },
    '&>.MuiAccordionSummary-content': {
      flexGrow: 0,
      '&::before': {
        color: COLORS.DARK_BLUE,
        content: ({ AccordionSummaryTextMore }) =>
          `'${AccordionSummaryTextMore}'`,
      },
      '&.Mui-expanded::before': {
        color: COLORS.DARK_BLUE,
        content: ({ AccordionSummaryTextLess }) =>
          `'${AccordionSummaryTextLess}'`,
      },
    },
    '&::before': {
      content: 'close-quote',
      width: '100%',
      background: `linear-gradient(rgba(250,250,250,0),rgb(248,248,248))`,
      height: 30,
      flex: '1 0 100%',
      marginTop: -30,
      transition: 'height 300ms, margin 300ms',
    },
    '&.Mui-expanded&::before': {
      height: 0,
      marginTop: 0,
    },
  },
  description: {
    marginTop: 28,
    padding: 0,
    justifyContent: ' flex-start',
  },
  role: {
    marginBottom: 24,
    fontWeight: 600,
  },
  image: {
    filter: 'grayscale(100%)',
    width: '100%',
  },
});

const PersonCard = ({
  name,
  role,
  imgFluid,
  description,
  email,
  phone,
  className,
  ...restProps
}) => {
  const { formatMessage } = useIntl();
  const classes = useStyle({
    AccordionSummaryTextMore: formatMessage({
      id: 'PersonCard.button.more',
    }),
    AccordionSummaryTextLess: formatMessage({
      id: 'PersonCard.button.less',
    }),
  });

  return (
    <Grid
      item
      xs={12}
      {...restProps}
      className={`${classes.PersonCard} ${className}`}
    >
      <Grid container justify="space-between">
        {imgFluid && (
          <Grid item xs={12} sm={6} md={5}>
            <Img
              fluid={imgFluid}
              alt={name}
              className={classes.image}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={5} md={6}>
          <Grid container>
            <Grid item xs={12} className={classes.name}>
              {name}
            </Grid>
            {role && (
              <Grid item xs={12} className={classes.role}>
                {role}
              </Grid>
            )}
            {phone && (
              <Grid item xs={12}>
                <a
                  href={`tel:${phone}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatPhoneNumber(phone)}
                </a>
              </Grid>
            )}
            {email && (
              <Grid item xs={12}>
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {email}
                </a>
              </Grid>
            )}
            {description && (
              <Grid item xs={12}>
                {description.length >
                amountLettersToShowAccordion ? (
                  <Accordion
                    elevation={0}
                    className={classes.accordion}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      className={classes.rootSummary}
                    />
                    <AccordionDetails
                      className={classes.description}
                    >
                      {description}
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <p className={classes.description}>
                    {description}
                  </p>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PersonCard.propTypes = {
  imgFluid: shape(),
  name: string.isRequired,
  role: string,
  email: string,
  phone: string,
  description: string,
  className: string,
};

/**TODO:SET default image by passing static image*/
PersonCard.defaultProps = {
  img: null,
  role: null,
  email: null,
  phone: null,
  description: null,
  className: '',
};

export default PersonCard;
