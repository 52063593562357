import React, { useState, useEffect } from 'react';
import PageSection from '../Cards/PageSection';
import { Grid } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import SustainableDevCard from '../Cards/SustainableDevCard-v2';

const Index = () => {
  const data = useStaticQuery(query);
  const sustainableDevCardsData =
    data.allContentfulScanshipSustainableDevelopment.edges;
  const [flippedIndex, setFlippedIndex] = useState(-1);
  const [autoFlip, setAutoFlip] = useState(true);

  let isActualPagePostReversed = false;

  useEffect(() => {
    setTimeout(() => {
      setFlippedIndex(0);
    }, 1000);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setFlippedIndex(
        flippedIndex === sustainableDevCardsData.length - 1
          ? 0
          : flippedIndex + 1
      );
    }, 5000);
  }, [flippedIndex]);

  return (
    <Grid container>
      {data.allContentfulScanshipPageSection.edges.map(
        (
          {
            node: {
              image,
              title,
              description: { description },
            },
          },
          i
        ) => {
          isActualPagePostReversed = !isActualPagePostReversed;
          return (
            <PageSection
              key={title}
              imgFluid={image && image.fluid}
              title={title}
              description={description}
              reversed={isActualPagePostReversed}
              paddingTop={i !== 0}
              paddingBottom={
                i !==
                data.allContentfulScanshipPageSection.edges
                  .length -
                  1
              }
              customMedia={
                title
                  .toLowerCase()
                  .includes('sustainable development') ? (
                  <Grid
                    container
                    onMouseEnter={() => setAutoFlip(false)}
                    onMouseLeave={() => setAutoFlip(true)}
                  >
                    {sustainableDevCardsData.map(
                      (
                        {
                          node: {
                            title,
                            goalNumber,
                            description: { description },
                          },
                        },
                        i
                      ) => (
                        <SustainableDevCard
                          key={title}
                          xs={6}
                          goalNumber={parseInt(goalNumber)}
                          title={title}
                          description={description}
                          flipped={
                            i === flippedIndex && autoFlip
                          }
                        />
                      )
                    )}
                  </Grid>
                ) : null
              }
            />
          );
        }
      )}
    </Grid>
  );
};

export default Index;

const query = graphql`
  query AboutUS {
    allContentfulScanshipPageSection(
      filter: { page: { eq: "/about-us" } }
      sort: { fields: createdAt, order: ASC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          title
          description {
            description
          }
        }
      }
    }
    allContentfulScanshipSustainableDevelopment(
      sort: { fields: goalNumber, order: ASC }
    ) {
      edges {
        node {
          goalNumber
          title
          description {
            description
          }
        }
      }
    }
  }
`;
