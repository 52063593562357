import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import SIZES from '../../constants/sizes';
import FONTS from '../../constants/fonts';
import COLORS from '../../constants/colors';

const useStyles = makeStyles(theme => ({
  CustomersRest: {
    paddingLeft: SIZES.PADDING.LEFT_PADDING.DEFAULT + 55,
    paddingRight: SIZES.PADDING.RIGHT_PADDING.DEFAULT,
    paddingTop: 98,
    [theme.breakpoints.down('md')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.MD + 55,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.MD,
      paddingTop: 48,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: SIZES.PADDING.LEFT_PADDING.XS,
      paddingRight: SIZES.PADDING.RIGHT_PADDING.XS,
      paddingTop: 60,
    },
  },
  Header: {
    paddingBottom: 60,
    fontSize: FONTS.SIZE.XXM,
    color: COLORS.DARK_BLUE,
    fontWeight: FONTS.WEIGHT.BOLD,
    [theme.breakpoints.down('md')]: {
      fontSize: FONTS.SIZE.XL,
    },
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 60,
      fontSize: FONTS.SIZE.L,
    },
  },
  imagesContainer: {
    margin: -50,
    marginBottom: 0,
    [theme.breakpoints.down('md')]: { margin: 0 },
    [theme.breakpoints.down('xs')]: { margin: 0 },

    '&>div': {
      padding: '50px 70px',
      alignItems: 'center',
      alignSelf: 'center',
      display: 'grid',
      [theme.breakpoints.down('md')]: { padding: 25 },
    },
  },
  blueDot: {
    width: 8,
    height: 8,
    backgroundColor: COLORS.BLUE,
    display: 'inline-flex',
    alignSelf: 'center',
    transform: 'translateY(-50%)',
    marginLeft: 11,
  },
  subHeader: {
    marginBottom: 40,
    fontWeight: 600,
    paddingLeft: 20,
  },
}));

const CustomersRest = () => {
  const data = useStaticQuery(query);
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.CustomersRest}>
        <Grid container>
          <Grid item xs={12}>
            <h3 className={classes.subHeader}>Yards</h3>
          </Grid>
          <Grid
            container
            className={classes.imagesContainer}
          >
            {data.allContentfulScanshipOrderedList.edges[0].node.items.map(
              ({
                partnerImage: { fluid },
                partnerName,
              }) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  key={partnerName}
                >
                  <Img fluid={fluid} alt={partnerName} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.CustomersRest}>
        <Grid container>
          <Grid item xs={12}>
            <h3 className={classes.subHeader}>
              Aquaculture
            </h3>
          </Grid>
          <Grid
            container
            className={classes.imagesContainer}
          >
            {data.aquaculture.edges[0].node.items.map(
              ({
                customerImage: { fluid },
                customerName,
              }) => (
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={3}
                  key={customerName}
                >
                  <Img fluid={fluid} alt={customerName} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomersRest;

const query = graphql`
  query CustomersYard {
    allContentfulScanshipOrderedList(
      filter: {
        listId: { eq: "/about-us/references/#yards" }
      }
    ) {
      edges {
        node {
          items {
            ... on ContentfulScanshipCustomerYard {
              partnerName
              partnerImage {
                fluid {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    aquaculture: allContentfulScanshipOrderedList(
      filter: {
        listId: { eq: "/about-us/references/#aquaculture" }
      }
    ) {
      edges {
        node {
          items {
            ... on ContentfulScanshipCustomerAquaculture {
              customerName
              customerImage {
                fluid {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
